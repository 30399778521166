@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');


/* Basic Styling for the Page */
.App {
  font-family: Arial, sans-serif;
  text-align: center;
}

html, body {
  overflow-x: hidden;
}


/* Image Container */
.image-container {
  position: relative;
  height: 100vh;
  width: 100vw;
  background: url('./assets/Trafari_Main2.png') no-repeat center center/cover;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Moving Images */
.moving-images {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.moving-image {
  position: absolute; width: 5%; height: auto;
  overflow: hidden; 
}

/* Positions for Moving Images */
.moving-image1 { position: absolute; width: 5%; height: auto; top: 20%; left: 70%; }
.moving-image2 { position: absolute; width: 5%; height: auto; top: 20%; left: 73%; }
.moving-image3 { position: absolute; width: 5%; height: auto; top: 18%; left: 70%; }
.moving-image4 { position: absolute; width: 5%; height: auto; top: 18%; left: 74%; }
.moving-image5 { position: absolute; width: 5%; height: auto; top: 25%; left: 65%; }
.moving-image6 { position: absolute; width: 5%; height: auto; top: 26%; left: 68%; }
.moving-image7 { position: absolute; width: 5%; height: auto; top: 25%; left: 75%; }
.moving-image8 { position: absolute; width: 5%; height: auto; top: 25%; left: 79%; }
.moving-image9 { position: absolute; width: 5%; height: auto; top: 24%; left: 72%; }
.moving-image10 { position: absolute; width: 5%; height: auto;  top: 24%; left: 74%; }
.moving-image11 { position: absolute; width: 5%; height: auto; top: 30%; left: 77%; }
.moving-image12 { position: absolute; width: 5%; height: auto; top: 30%; left: 77%; }
.moving-image13 { position: absolute; width: 5%; height: auto; top: 21%; left: 78%; }
.moving-image14 { position: absolute; width: 5%; height: auto; top: 21%; left: 78%; }
.moving-image15 { position: absolute; width: 5%; height: auto; top: 27%; left: 67%; }
.moving-image16 { position: absolute; width: 5%; height: auto; top: 29%; left: 70%; }

/* Logo and Overlay Elements */
.Logo,
.overlay,
.demo-overlay-image {
  z-index: 2;
}

.center-content1 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Align content slightly left */
  transform: translate(-40%, -25%); /* Offset slightly to the left */
  max-width: 600px;
  color: white;
  text-align: center;
  z-index: 2;
}

/* Logo Styling */
.Logo {
  width: 70%;
  height: auto;
  margin-bottom: 20px;
}

/* Overlay paragraph styling */
.overlay p {
  font-size: 2vw;
  max-width: 600px;
  font-family: 'Lilita One', sans-serif;
  margin: 0;
  text-align: center; /* Align text within the container */
}

/* Button group styling */
.button-group {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

/* Buttons styling */
.button {
  background: none;
  padding: 12px 24px;
  color: white;
  font-size: 1.2em;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  border: none;
}

.button-student {
  background-color: #33c2d4;
  font-family: 'Lilita One', sans-serif;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
  border-radius: 9px;
}

.button-student:hover {
  background-color: #2aa3b7;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

.button-admin {
  background-color: #ff7d4d; 
  font-family: 'Lilita One', sans-serif;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
  border-radius: 9px;
}

.button-admin:hover {
  background-color: #e66c3f;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

/* Demo overlay image positioned slightly to the right of center */
.demo-overlay-image {
  position: absolute;
  top: 50%;
  left: 61%; /* Offset to the right of center */
  transform: translate(10%, -50%);
  width: 320px;
  height: 610px;
  z-index: 2;
}

.overlay1 {
  position: absolute;
  top: 5%;
  left: 5%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Stack elements vertically */
  color: white;
  text-align: center;
}

.center-content {
  max-width: 600px;
  margin-left: 30%; /* Adjusts offset without causing overflow */
  margin-top: -5%;
}

.overlay1 p {
  font-size: 2vw;
  max-width: 600px;
  font-family: 'Lilita One', sans-serif;
  margin: 0 auto;
  padding: 0; /* Ensure no padding shifts position */
}

.large-text {
  font-size: 3.3vw;
}

.small-text {
  font-size: 1.5vw;
  font-style: italic;
}

.extra-small-text {
  font-size: 1.2vw;
  font-style: italic;
}

.sp-image {
  position: absolute;
  top: -20%;
  left: 20%;
  width: 20%;
  height: auto;
  transform: rotate(7deg);
}

.sgp-image {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 20%;
  height: auto;
  transform: rotate(-7deg);
}

.fp-image {
  position: absolute;
  top: 30%;
  left: 20%;
  width: 20%;
  height: auto;
  transform: rotate(7deg);
}

.pp-image {
  position: absolute;
  top: 55%;
  left: 5%;
  width: 20%;
  height: auto;
  transform: rotate(-7deg);
}

/* Form Section */
.form-section {
  padding: 10px;
  background-color: #391a8a;
  color: white; /* Default text color for headers and labels */
  margin: auto; /* Center align the form */
  font-family: 'Lilita One', sans-serif;
  padding-bottom: 100px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
  
}

input,
textarea {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Submit Button */
button[type='submit'] {
  background-color: #28a745;
  border: none;
  padding: 10px 20px;
  color: white;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  font-family: 'Lilita One', sans-serif;
}

button[type='submit']:hover {
  background-color: #218838;
}

.privacy-policy-container {
  background-color: #391a8a; /* Purple */
  color: white; /* White text */
  padding: 10px;
  padding-left: 150px;
  min-height: 100vh; /* Full-height page */
  display: flex;
  flex-direction: column;
  text-align: left;
}

.privacy-policy-container h1 {
  margin-bottom: 20px;
}

.privacy-policy-container p {
  max-width: 800px;
  line-height: 1.6;
  text-align: justify;
}

.privacy-policy-container ul {
  list-style-position: outside; /* Ensures bullets are outside the text block */
  padding-left: 20px; /* Adds space between the bullets and the container */
  text-align: left; /* Left-aligns the list */
}

.privacy-policy-container li {
  margin-bottom: 10px; /* Adds space between list items */
  line-height: 1.6; /* Improves readability */
}

footer {
  background-color: #391a8a; /* Purple background */
  padding: 10px 20px;
  text-align: center;
}

footer a {
  color: white; /* White hyperlink text */
  text-decoration: none; /* No underline by default */
  font-weight: bold; /* Make it bold (optional) */
}

footer a:hover {
  text-decoration: underline; /* Add underline on hover */
  opacity: 0.8; /* Slight transparency on hover (optional) */
}



/* Media Queries for Mobile Responsiveness */
/* Base styles remain the same... */

/* Media query for mobile devices */
@media (max-width: 768px) {

  body {
    overflow-x: hidden;
    
  }

  /* Image container adjustments */
  .image-container {
    height: auto;
    min-height: 100vh;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* overflow-x: hidden; */
  }

  .moving-image {
    width: 20% !important; /* Drastically increase size */
    height: auto !important; /* Maintain aspect ratio */
  }

  .moving-images {
    top: 35%;
    left: -33%;
    /* width: 100%; 
    height: 100%; 
    transform: scale(2.2); 
    transform-origin: center;  */
  } 


  .center-content1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    text-align: center;
    margin-bottom: 20px;
    max-width: 100%; 
    padding-top: 25px;
    transform: none; 
  }

  /* Adjust logo size for mobile */
  .Logo {
    width: 50%;
    margin-bottom: 15px;
  }

  /* Center-align paragraph and adjust width */
  .overlay p {
    font-size: 5vw;
    max-width: 90%; /* Ensures it aligns well with other elements */
    margin: 0 auto; /* Center within the container */
  }

  /* Button group styling for vertical alignment */
  .button-group {
    flex-direction: column;
    gap: 15px;
  }

  /* Demo image sizing adjustments to keep proportions */
  .demo-overlay-image {
    position: relative;
    left: 0;
    transform: none;
    width: 70%;
    /* max-width: 250px; */
    height: auto; /* Maintain aspect ratio */
    /* max-height: 450px; Limit height to prevent elongation */
    margin: 20px auto 0;
  }

  .center-content {
    transform: translate(20%, -5%); /* Offset horizontally and vertically */
    
  }

  .overlay1 {
    min-height: 100vh;
    top: 0;
    left: -30px;
  }

  .overlay1 p {
    max-width: 50%; /* Ensure the text fits well on mobile */
    font-size: 4vw; /* Adjust font size for readability */
    margin: 0 auto;
  }

  .large-text {
    font-size: 6.5vw !important;
  }
  
  .small-text {
    font-size: 4vw !important;
    font-style: italic;
  }
  
  .extra-small-text {
    font-size: 3.4vw !important;
  }

  /* Adjust sizing and positioning of images */
  .sp-image,
  .sgp-image,
  .fp-image,
  .pp-image {
    width: 44%; /* Increase image size for mobile */
    margin-top: 5px; /* Reduce spacing between images */
  }

  /* Specific vertical positioning adjustments for each image */
  .sp-image {
    top: 0%; /* Move image down for better spacing */
  }

  .sgp-image {
    top: 22%; /* Move image slightly up for better alignment */
  }

  .fp-image {
    top: 44%; /* Bring images closer vertically */
  }

  .pp-image {
    top: 66%; /* Bring images closer vertically */
  }

  /* Font size adjustments for readability */
  .large-text {
    font-size: 5vw;
  }

  .small-text {
    font-size: 3vw;
  }

  .extra-small-text {
    font-size: 2vw;
  }

  .form-section {
    padding: 25px;
    padding-bottom: 120px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 250px;
    margin: 0 auto;
    padding: 5px; /* Remove padding if any */
  }
  
  input,
  textarea {
  padding: 10px;
  width: 100%;
  max-width: 250px;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: auto; /* Ensures inputs don’t add extra height */
  margin-bottom: 10px; /* Adjusts spacing between elements */
  }

  button[type='submit'] {
    margin-top: 20px; /* Adjust this value for desired spacing */
  }

  .privacy-policy-container {
    padding-left: 30px;
  }
}
